<template>
  <div>
    <h1>CONDICIONES GENERALES</h1>
    <h2>OBJETO</h2>
    <p>
      Las presentes Condiciones Generales regulan la adquisición de los
      productos ofertados en el sitio web
      <a href="http://www.frombea.com/">http://www.frombea.com/</a>, del que es
      titular FROMBEA S.L. (en adelante, from:BEA).
    </p>
    <p>
      La adquisición de cualesquiera de los productos conlleva la aceptación
      plena y sin reservas de todas y cada una de las Condiciones Generales que
      se indican, sin perjuicio de la aceptación de las Condiciones Particulares
      que pudieran ser de aplicación al adquirir determinados productos.
    </p>
    <p>
      Estas Condiciones Generales podrán ser modificadas sin notificación
      previa, por tanto, es recomendable leer atentamente su contenido antes de
      proceder a la adquisición de cualquiera de los productos ofertados.
    </p>
    <p>
      Una vez el Usuario marque la casilla correspondiente en el proceso, se
      producirá la aceptación expresa de las presentes CGCO, declarando y
      reconociéndose ambas partes su capacidad jurídica y de obrar, la mayoría
      de edad del Usuario (es decir, que ha cumplido 18 años) así como que ha
      leído y comprendido el contenido de las mismas.
    </p>
    <h2>IDENTIFICACIÓN</h2>
    <p>
      from:BEA, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios
      de la Sociedad de la Información y de Comercio Electrónico, le informa de
      que:
    </p>
    <ul>
      <li>Su denominación social es:&nbsp;<span>FROMBEA</span>&nbsp;S.L.</li>
      <li>Su nombre comercial es: from:BEA.</li>
      <li>Su CIF es: B67431627.</li>
      <li>
        Su domicilio social está en: Calle Hercegovina, 19 Local Nº 2 – 08006
        Barcelona (ESPAÑA).
      </li>
    </ul>
    <p>
      Por su parte, el comprador será el Usuario que se registre consignando sus
      datos personales, previa aceptación de las presentes
      <strong>CGCO</strong>.
    </p>
    <p>
      Se informa que para poder utilizar este servicio de contratación
      <em>on line</em>, el Usuario debe ser mayor de edad conforme a la
      legislación española.
    </p>
    <p>
      De este modo, las indicadas partes aceptan expresamente, sin reserva
      alguna, lo previsto en las presentes CGCO. En el caso de que el comprador
      no estuviese conforme con ellas, únicamente debe abandonar el proceso de
      compra previsto en este portal, al igual que el sitio web
      <a href="http://www.frombea.com">http://www.frombea.com</a> si no
      estuviese conforme con sus Condiciones de Uso.
    </p>
    <h2>COMUNICACIONES</h2>
    <p>
      Para comunicarse con nosotros, ponemos a su disposición diferentes medios
      de contacto que detallamos a continuación:
    </p>
    <ul>
      <li>Tfno: +34 659 833 811</li>
      <li>Email: <a href="mailto:hi@frombea.com">hi@frombea.com</a>.</li>
      <li>
        Dirección postal: Calle Hercegovina, 19 Local Nº 2 – 08006 Barcelona
        (ESPAÑA).
      </li>
    </ul>
    <p>
      Todas las notificaciones y comunicaciones entre los usuarios y from:BEA se
      considerarán eficaces, a todos los efectos, cuando se realicen a través de
      cualquier medio de los detallados anteriormente.
    </p>
    <p>
      from:BEA tiene su Atelier y Showroom en&nbsp;<span
        >Calle Hercegovina, 19 Local Nº 2 – 08006 Barcelona (ESPAÑA).</span
      >
    </p>
    <p>
      No hacemos venta directa, pero puedes venir a visitarnos cuando quieras.
    </p>
    <h2>PRODUCTOS</h2>
    <p>
      Los productos ofertados, junto con las características principales de los
      mismos y su precio aparecen en pantalla.
    </p>
    <p>
      Nuestros tiempos de producción son de 7 a 15 días hábiles ya que
      realizamos cada producto de manera artesanal.
    </p>
    <p>
      from:BEA se reserva el derecho de decidir, en cada momento, los productos
      y/o servicios que se ofrecen a los Clientes. De este modo, from:BEA podrá,
      en cualquier momento, añadir nuevos productos y/o servicios a los
      ofertados actualmente. Asimismo from:BEA se reserva el derecho a retirar o
      dejar de ofrecer, en cualquier momento, y sin previo aviso, cualesquiera
      de los productos ofrecidos.
    </p>
    <p>
      Una vez dentro de
      <a href="http://www.frombea.com/">http://www.frombea.com/</a>,y para
      acceder a la contratación de los distintos productos, el Usuario deberá
      seguir todas las instrucciones indicadas en el proceso de compra, lo cual
      supondrá la lectura y aceptación de todas las condiciones generales y
      particulares fijadas en
      <a href="http://www.frombea.com/">http://www.frombea.com/</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsConditionsContent',
};
</script>

<style lang="scss" src="@/assets/css/legal.scss" scoped></style>
