var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"size":"xl","hide-footer":"","id":"modal-terms-conditions","title":_vm.$t('Términos y condiciones')}},[_c('TermsConditionsContent')],1),_vm._v(" "),_c('section',{staticClass:"first-section"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"left",attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("\n            "+_vm._s(_vm.$t('Tu ropa de casa'))),_c('br'),_vm._v("\n            "+_vm._s(_vm.$t('siempre a tu gusto'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('Crea tu producto a través de nuestro simulador.'))),_c('br'),_vm._v("\n            "+_vm._s(_vm.$t(
                'También puedes visitar nuestra colección de productos ya diseñados.'
              ))+"\n          ")]),_vm._v(" "),_c('b-row',{staticClass:"d-flex justify-center flex-gap mb-4 mb-md-0"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"btn-cta",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.scrollToDesignNow()}}},[_vm._v(_vm._s(_vm.$t('Crea tu producto'))+"\n              ")])],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"btn-cta",attrs:{"variant":"primary","to":_vm.localePath({
                    name: 'catalog-slug-pslug',
                    params: {
                      slug: _vm.getTranslatedKey(
                        _vm.catalogIndividualesYServilletasCategory.parent,
                        'slug'
                      ),
                      pslug: _vm.getTranslatedKey(
                        _vm.catalogIndividualesYServilletasCategory.child,
                        'slug'
                      ),
                    },
                  })}},[_vm._v(_vm._s(_vm.$t('Nuestra colección'))+"\n              ")])],1)],1)],1),_vm._v(" "),_c('b-col',{staticClass:"right",style:({ backgroundImage: 'url(' + _vm.ctaImageURI + ')' }),attrs:{"cols":"12","md":"6"}})],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"third-section"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"12","md":"4"}},[_c('img',{attrs:{"src":require("@/assets/img/artesanal.png")}}),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t('100% Artesanal')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Un producto sofisticado y único hecho a mano para ti, en España y sin procesos industriales.'
              ))+"\n          ")])]),_vm._v(" "),_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"12","md":"4"}},[_c('img',{attrs:{"src":require("@/assets/img/100artesanal-copia.png")}}),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t('Alta Durabilidad')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Gracias a su larga vida utiliza nuestros productos tanto como los manteles y sábanas de la abuela.'
              ))+"\n          ")])]),_vm._v(" "),_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"12","md":"4"}},[_c('img',{attrs:{"src":require("@/assets/img/record.png")}}),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t('Fabricación récord')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Obtén tu producto en un plazo máximo de 15 días sin sacrificar ni un ápice de calidad.'
              ))+"\n          ")])])],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"banner1-section"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-container',{staticClass:"pb-4 pt-4",attrs:{"fluid":""}},[_c('b-row',{staticClass:"text-center mb-4"},[_c('b-col',[_c('h2',[_vm._v("\n              "+_vm._s(_vm.$t('Crea tu producto en nuestro simulador'))+"\n            ")])])],1),_vm._v(" "),_c('b-row',{staticClass:"justify-content-md-center text-center"},[_c('b-col',{staticClass:"banner1-ps-wrapper mb-5 mb-lg-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('b-link',{attrs:{"to":_vm.localePath({
                  name: 'simulator-slug-pslug',
                  params: {
                    slug: _vm.getTranslatedKey(_vm.ropaDeMesaCategory, 'slug'),
                    pslug: _vm.getTranslatedKey(
                      _vm.ropaDeMesaIndividualesCategory,
                      'slug'
                    ),
                  },
                })}},[_c('img',{attrs:{"src":_vm.ctaImageSimulator1URI}}),_vm._v(" "),_c('div',[_c('b-button',{staticClass:"btn-cta",attrs:{"variant":"primary","block":""}},[_vm._v(_vm._s(_vm.$t('Crea tu individual'))+"\n                ")])],1)])],1),_vm._v(" "),_c('b-col',{staticClass:"banner1-ps-wrapper mb-5 mb-lg-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('b-link',{attrs:{"to":_vm.localePath({
                  name: 'simulator-slug-pslug',
                  params: {
                    slug: _vm.getTranslatedKey(_vm.ropaDeMesaCategory, 'slug'),
                    pslug: _vm.getTranslatedKey(
                      _vm.ropaDeMesaServilletasCategory,
                      'slug'
                    ),
                  },
                })}},[_c('img',{attrs:{"src":_vm.ctaImageSimulator2URI}}),_vm._v(" "),_c('div',[_c('b-button',{staticClass:"btn-cta",attrs:{"variant":"primary","block":""}},[_vm._v(_vm._s(_vm.$t('Crea tu servilleta'))+"\n                ")])],1)])],1),_c('b-col',{staticClass:"banner1-ps-wrapper mb-5 mb-lg-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('b-link',{attrs:{"to":_vm.localePath({
                  name: 'simulator-slug-pslug',
                  params: {
                    slug: _vm.getTranslatedKey(_vm.ropaDeMesaCategory, 'slug'),
                    pslug: _vm.getTranslatedKey(
                      _vm.ropaDeMesaMantelesCategory,
                      'slug'
                    ),
                  },
                })}},[_c('img',{attrs:{"src":_vm.ctaImageSimulator3URI}}),_vm._v(" "),_c('div',[_c('b-button',{staticClass:"btn-cta",attrs:{"variant":"primary","block":""}},[_vm._v(_vm._s(_vm.$t('Crea tu mantel'))+"\n                ")])],1)])],1),_c('b-col',{staticClass:"banner1-ps-wrapper mb-5 mb-lg-0",attrs:{"cols":"12","sm":"6","md":"6","lg":"3"}},[_c('b-link',{attrs:{"to":_vm.localePath({
                  name: 'simulator-slug-pslug',
                  params: {
                    slug: _vm.getTranslatedKey(_vm.ropaDeMesaCategory, 'slug'),
                    pslug: _vm.getTranslatedKey(
                      _vm.ropaDeMesaCaminosMesaCategory,
                      'slug'
                    ),
                  },
                })}},[_c('img',{attrs:{"src":_vm.ctaImageSimulator4URI}}),_vm._v(" "),_c('div',[_c('b-button',{staticClass:"btn-cta",attrs:{"variant":"primary","block":""}},[_vm._v(_vm._s(_vm.$t('Crea tu camino de mesa'))+"\n                ")])],1)])],1)],1)],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"only-lino-section"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"12","offset-md":"4","md":"4"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/linoalgodon.png")}}),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('Solo escogemos telas e hilos de fibras 100% naturales.'))+"\n          ")])])],1),_vm._v(" "),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mt-4 btn-cta",attrs:{"variant":"primary","to":_vm.localePath({ name: 'fabrics' })}},[_vm._v(_vm._s(_vm.$t('Visita nuestro catálogo de telas'))+"\n              ")])],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"criteria-providers-section"},[_c('b-container',[_c('b-row',[_c('p',{staticClass:"heading"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'Evaluamos a 30 proveedores europeos y solo escogemos los que cumplen con nuestros criterios claves:'
            ))+"\n        ")])]),_vm._v(" "),_c('b-row',{staticClass:"justify-content-center justify-content-md-between text-center"},[_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/soste.png")}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Sostenibilidad')))])]),_vm._v(" "),_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/diamond.png")}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Calidad')))])]),_vm._v(" "),_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/users-1.png")}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Responsabilidad empresarial')))])]),_vm._v(" "),_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/earth.png")}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Respeto al medioambiente')))])]),_vm._v(" "),_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/users-1.png")}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Comercio justo')))])])],1),_vm._v(" "),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mt-4 btn-cta",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.scrollToDesignNow()}}},[_vm._v(_vm._s(_vm.$t('Crea tu producto'))+"\n              ")])],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mt-4 btn-cta",attrs:{"variant":"primary","to":_vm.localePath({
                    name: 'catalog-slug-pslug',
                    params: {
                      slug: _vm.getTranslatedKey(
                        _vm.catalogIndividualesYServilletasCategory.parent,
                        'slug'
                      ),
                      pslug: _vm.getTranslatedKey(
                        _vm.catalogIndividualesYServilletasCategory.child,
                        'slug'
                      ),
                    },
                  })}},[_vm._v(_vm._s(_vm.$t('Nuestra colección'))+"\n              ")])],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"quality-work-section"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"justify-content-md-between"},[_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"12","md":"5"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/TALLER.jpg")}})]),_vm._v(" "),_c('b-col',{staticClass:"col-wrapper wrapper-text",attrs:{"cols":"12","md":"7"}},[_c('b-row',{staticClass:"col-wrapper-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mr-3"},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":"star-fill"}}),_c('b-icon',{attrs:{"icon":"star-fill","font-scale":"1.5"}}),_c('b-icon',{attrs:{"icon":"star-fill","font-scale":"1.5"}}),_c('b-icon',{attrs:{"icon":"star-fill","font-scale":"1.5"}}),_c('b-icon',{attrs:{"icon":"star-fill","font-scale":"1.5"}})],1),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'Todos nuestros trabajos son únicos y 100% a medida. Nuestra manera de hacer el diseño más puro: no añadimos etiquetas porque el protagonista eres tú.'
                  ))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"text2"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    '– Lo que nos garantiza en nuestros 5 años de vida tener cero devoluciones de nuestros clientes. –'
                  ))+"\n              ")])])],1)],1)],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"history-frombea"},[_c('b-container',[_c('b-row',{staticClass:"justify-content-md-between",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"12"}},[_c('p',[_vm._v(_vm._s(_vm.$t('CONOCE MÁS SOBRE NUESTRA HISTORIA')))]),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t('La historia de from:BEA')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Era tradición en casa de su abuela y luego en la de su madre el poner mesas enormes cuidando hasta el último detalle, pues estas casas siempre estaban abiertas y había invitados a los que cuidar.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Esta tradición y su paso por el diseño de interiores hacen hoy la marca de from:BEA.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'En su profesión como interiorista siempre buscaba el personalizar la ropa de casa y sobre todo de la mejor calidad.'
              ))),_c('br'),_c('br'),_vm._v(_vm._s(_vm.$t('Y fue cuando tomó la decisión de montar su propio taller.'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Esta idea comenzó en casa de Bea, que después de dedicar mucho tiempo a la investigación de los tejidos naturales y en particular del lino, que es un tejido vivo, pudo crear su propio sistema de producción.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Apostó por este tejido, el lino, porque a parte de vestir mucho y dar más calidez al entorno, su producción es la menos contaminante y es totalmente biodegradable.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Hoy fabricamos Ropa de Mesa, Ropa de Cama, Ropa de Baño y Ropa de Casa.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Por nuestros principios y forma de hacer las cosas, from:BEA ha crecido de manera exponencial.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Y nos enorgullece saber que nuestro porcentaje de CERO devoluciones es la prueba de ello.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'Te damos la bienvenida a from:BEA – Tu ropa de casa siempre a tu gusto.'
              ))+"\n          ")])])],1),_vm._v(" "),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mt-4 btn-cta",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.scrollToDesignNow()}}},[_vm._v(_vm._s(_vm.$t('Crea tu producto'))+"\n              ")])],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mt-4 btn-cta",attrs:{"variant":"primary","to":_vm.localePath({
                    name: 'catalog-slug-pslug',
                    params: {
                      slug: _vm.getTranslatedKey(
                        _vm.catalogIndividualesYServilletasCategory.parent,
                        'slug'
                      ),
                      pslug: _vm.getTranslatedKey(
                        _vm.catalogIndividualesYServilletasCategory.child,
                        'slug'
                      ),
                    },
                  })}},[_vm._v(_vm._s(_vm.$t('Nuestra colección'))+"\n              ")])],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"manual-book-linen-care-section"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"col-wrapper",attrs:{"cols":"12","offset-md":"3","md":"6"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/linoalgodoncare.png")}}),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('El libro del cuidado del lino'))+"\n          ")])])],1),_vm._v(" "),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mt-2 btn-cta",attrs:{"variant":"primary","href":_vm.$i18n.locale === 'en'
                    ? '/files/Lino_y_su_cuidado_en.pdf'
                    : '/files/Lino_y_su_cuidado.pdf',"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('Descargar'))+"\n              ")])],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('section',{staticClass:"say-hello-section"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"text-center mb-4"},[_c('b-col',[_c('p',{staticClass:"text-hello"},[_vm._v("\n            "+_vm._s(_vm.$t('Dinos hola y síguenos para estar al día con'))+"\n            "),_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/from_bea/"}},[_vm._v("@frombea")])])])],1),_vm._v(" "),_c('b-row',{staticClass:"justify-content-md-center text-center"},[_c('b-col',{staticClass:"banner1-ps-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{attrs:{"src":_vm.getImageSocial(
                1,
                require('@/assets/img/PastedGraphic-5-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                5,
                require('@/assets/img/PastedGraphic-11-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                9,
                require('@/assets/img/PastedGraphic-7-280x280.jpg')
              )}})]),_vm._v(" "),_c('b-col',{staticClass:"banner1-ps-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{attrs:{"src":_vm.getImageSocial(
                2,
                require('@/assets/img/PastedGraphic-14-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                6,
                require('@/assets/img/PastedGraphic-10-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                10,
                require('@/assets/img/PastedGraphic-6-280x280.jpg')
              )}})]),_c('b-col',{staticClass:"banner1-ps-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{attrs:{"src":_vm.getImageSocial(
                3,
                require('@/assets/img/PastedGraphic-13-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                7,
                require('@/assets/img/PastedGraphic-9-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                11,
                require('@/assets/img/PastedGraphic-15-280x280.jpg')
              )}})]),_vm._v(" "),_c('b-col',{staticClass:"banner1-ps-wrapper",attrs:{"cols":"6","md":"2"}},[_c('img',{attrs:{"src":_vm.getImageSocial(
                4,
                require('@/assets/img/PastedGraphic-12-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                8,
                require('@/assets/img/PastedGraphic-8-280x280.jpg')
              )}}),_vm._v(" "),_c('img',{attrs:{"src":_vm.getImageSocial(
                12,
                require('@/assets/img/PastedGraphic-16-1-280x280.jpg')
              )}})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }